* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: Montserrat !important; */
  /* font-weight: bold; */
  /* cursor: none; */
  /* overflow: hidden; */
  font-family: 'Montserrat', sans-serif;
}

*::-webkit-scrollbar {
  display: none;
}

cursor {
  cursor: auto;
}


@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
